<template>
  <div class="embed-responsive embed-responsive-16by9">
    <iframe
      :src="srcResolved"
      frameborder="0"
      scrolling="no"
      class="embed-responsive-item"
      :class="css"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    src: String,
    css: String,
  },

  computed: {
    srcResolved() {
      return this.src.replaceAll("&amp;", "&");
    },
  },
};
</script>